import React from "react";

export default function About() {
    return (
        <div>
            {/* <section class="abouthero innerhero">
                <div class="container">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>About Hingula Temple</h1>
                        </div>
                    </div>
                </div>
            </section> */}
            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>About Hingula Temple</h1>
                        </div>
                            <nav class="breadcrumb breadcrumb-custom">
                                <a class="breadcrumb-item" href="/">Home</a>
                                <span class="breadcrumb-item active">About</span>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section class="drowpdown-innertabs-mob-section about">
                <div class="container text-center">
                    <div class="dropdown drowpdown-innertabs-mob">
                        <button class="btn btn-primary dropdown-toggle btn-innertab" type="button" data-toggle="dropdown" aria-expanded="true">About
                            <span class="caret"></span></button>
                        <ul class="dropdown-menu">
                            {/* <li><a href="./about">About Us</a></li>  */}
                            <li><a href="/history">History</a></li>
                            <li><a href="/architecture">Architecture</a></li>
                            <li><a href="/deities">Deities</a></li>
                            <li><a href="/special-features">Features</a></li>
                            <li><a href="about">Maa Hingula</a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="about">
                <div class="container">

                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" align="center">
                        <span><img src={process.env.PUBLIC_URL + "assets/images/welcome1.jpeg"} class="img-responsive" alt="Idol of Maa Hingula" title="Maa Hingula Idol" /></span>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 animateOnScroll fadeInUp">
                        <div class="desktop-div">
                            <div class="subtitle">Welcome to</div>
                            <h2 class="font30">ମା ହିଙ୍ଗୁଳା ପୀଠ, ଶଙ୍ଖଚିଲା</h2>
                            <h2 class="font30" style={{ textAlign: "center" }}>ପୀଠ ମାହାତ୍ମ୍ୟ</h2>
                        </div>
                        <p>ମନ୍ଦିର ମାଳିନୀ ତୀର୍ଥ ଭୂମି ଓଡ଼ିଶା ଦେବା ଦେବୀଙ୍କର ପ୍ରିୟ ଲୀଳା ସ୍ଥଳ ଏବଂ ଦ୍ୱିତୀୟ ସ୍ବର୍ଗ ଭାବରେ ଚିର ବିଦୀତ। କେଉଁ ଅନାଦି କାଳରୁ ସହସ୍ର ଶକ୍ତି ପୀଠ ସମୟ ର ପିଛିଳ ପାବଛ ରେ ନିଜର ପାଦାଙ୍କ ସ୍ଥାପନ କରି ଆଧ୍ୟାତ୍ମିକ ତାର ଗଙ୍ଗୋତ୍ରୀ ପ୍ରବାହିତ କରିବା ସଙ୍ଗେ ସଙ୍ଗେ ଏ ମାଟିକୁ
                            ବିଶ୍ୱ ଦରବାରରେ ଅନନ୍ୟ କରି ଗଢି ତୋଳିଛନ୍ତି।ମାନବୀୟ ଲୀଳାର ମୋହ ସମ୍ବରଣ କରିନପାରି ଏଠାରେ ଦେବତା ଗଣ ଅବତାର ଗ୍ରହଣ କରିବାର ଦୃଷ୍ଟାନ୍ତ ବହୁ ବର୍ଣିତ। ସେହି ଦୈବୀ ମଣ୍ଡଳ ମଧ୍ୟରେ ଶଙ୍ଖଚିଲାର ମା' ହିଙ୍ଗୁଳାଙ୍କ ପୀଠ ବହୁ ପ୍ରାଚୀନ ହେଲେ ମଧ୍ୟ ସର୍ବଜନ ଦୃଷ୍ଟି ଅନ୍ତରାଳରେ ଥାଇ ପ୍ରଚାର
                            ପ୍ରସାରର ନିୟମକୁ ଆଲୋକିତ କରିଛି।</p>

                    </div>

                    <div class="clear"></div>


                    <br /><br /><br /><br />
                    <div class="col-xs-12 animateOnScroll fadeInUp">
                        <div class="aboutsubinner aboutsubinner2">
                            <div class="col-md-2 col-sm-4 col-xs-12" align="center"></div>
                            <div class="col-md-10 col-sm-8 col-xs-12">
                                <h3><a href="/about" style={{ textAlign: "center" }}>ଶଙ୍ଖଚିଲା</a></h3>
                            </div>
                            <div class="clear"></div>
                            <p class="more">ଶଙ୍ଖଚିଲା ବୋଲି କୋୖଣସି ନିର୍ଦ୍ଦିଷ୍ଟ ଗ୍ରାମ ନାହିଁ।ବାର ପାଟଣାର ସମାହାର ଶଙ୍ଖଚିଲା- ଏକ ପଞ୍ଚାୟତ- ଖମଣ ଗ୍ରାମ ପଞ୍ଚାୟତ ନାମରେ ନାମିତ।ବାର ଖଣ୍ଡ ଗ୍ରାମ ସଂଲଗ୍ନ ଏକ ସ୍ଥାନରେ ଏପରି ନିବିଡ଼ ଭାବରେ ଅବସ୍ଥିତ ଓ ପରିଚାଳିତ, ବିଭିନ୍ନତା ଏକତା ସୂତ୍ରରେ ସୁସଂଗଠିତ ହେବା ଅଧୁନା ଯୁଗବତ
                                ଏକ ବିସ୍ମୟ ସୃଷ୍ଟି କରେ। ଗ୍ରାମର ମଧ୍ୟ ସ୍ଥଳରେ ଏକ ବିରାଟ ପୁଷ୍କରିଣୀ 'ଶଙ୍ଖଚିଲା' ଗ୍ରାମ ନାମର ସ୍ମାରକୀ। ପୁଷ୍କରିଣୀ ପ୍ରତିଷ୍ଠା ସମୟରେ ଶୁଭ ଶଙ୍ଖଚିଲ ପକ୍ଷୀ ଚତୃ ଦିଗ ପ୍ରଦକ୍ଷିଣ କରି ମଧ୍ୟ ଭାଗରେ ନିର୍ମିତ ଶୁଭ ସ୍ତମ୍ଭ ବା ‘ଦିପିଦାଣ୍ଡି' ଉପରେ ବସି ଯିବାରୁ ଏତାଦୃଶ ଶଙ୍ଖଚିଲା
                                ନାମକରଣ ହୋଇଅଛି।</p>


                        </div>
                    </div>

                    <div class="col-xs-12 animateOnScroll fadeInUp">
                        <div class="aboutsubinner aboutsubinner2">
                            <div class="col-md-2 col-sm-4 col-xs-12" align="center"></div>
                            <div class="col-md-10 col-sm-8 col-xs-12">
                                <h3><a href="about" style={{ border: "none" }}>ମା ହିଙ୍ଗୁଳା ପୀଠ, ଶଙ୍ଖଚିଲା</a></h3>
                            </div>
                            <div class="clear"></div>
                            <p class="more" style={{ fontSize: "large" }}>ଓଡ଼ିଶାର ସାଂସ୍କୃତିକ ଓ ଧର୍ମୀୟ ପ୍ରେକ୍ଷାପଟରେ ମା’ ହିଙ୍ଗୁଳାଙ୍କ ଆବିର୍ଭାବ ବହୁ ପ୍ରାଚୀନତର ବୋଲି ପୂରାଣ ସାକ୍ଷ୍ୟ ପ୍ରଦାନ କରେ। କେଉଁ ଅନାଦି କାଳରୁ ମଧ୍ୟ ଯାଜପୁର ଜିଲ୍ଲାର ଶଙ୍ଖଚିଲା ଠାରେ ମା' ହିଙ୍ଗୁଳା ତାଙ୍କ ଅବାରିତ କରୁଣା ସ୍ରୋତରେ ଏ ମାଟିକୁ ଚିର ଅନନ୍ୟ ସାଧାରଣ ଭାବରେ
                                ପ୍ରତିପାଦିତ କରିଆସିଛନ୍ତି। ଅଦ୍ୟପି ଜନମାନସରେ ମା'ଙ୍କ ପୀଠ ସମ୍ବନ୍ଧୀତ ବହୁ ଜନରବ ଗୁଞରଣ ସୃଷ୍ଟି କରି ଆସୁଅଛି।ମା' ହିଙ୍ଗୁଳା ଦେବୀ ଯେ ଆଦ୍ୟାଶକ୍ତି ଅରୂପା, ଅଜନ୍ମା ଏବଂ ଅଗ୍ନି ସ୍ୱରୂପା ତାଙ୍କର ବିଭିନ୍ନ ଲୀଳା ମାଧ୍ୟମରେ ପ୍ରକଟିତ।ଯାହାର ସମସ୍ତ ନମୂନା ଶଙ୍ଖଚିଲାର କୋଣ
                                ଅନୁକୋଣରେ ଅନୁରଙ୍ଗିତ। ମହୋଦଧିରେ ଅପୋଢା ପିଣ୍ଡ ସେଦିନ ରାଜା ଇନ୍ଦ୍ରଦ୍ୟୁମ୍ନଙ୍କୁ ଅପେକ୍ଷା କରି ଥିବା ପରି ଆଜି ମା' ଙ୍କର ଅପ୍ରକଟିତ ମହିମାବନ୍ତ ଲୀଳାମୃତ ଏକ ପ୍ରସାରିତ ବାଲିଦାଣ୍ଡକୁ ପ୍ରତୀକ୍ଷା କରିଛି। <br /> ୧୯୨୯ ମସିହା ବୈଶାଖ ମାସ ୬ ଦିନ ଶୁକ୍ରବାର ଏ ଅଞ୍ଚଳ ପାଇଁ ଏକ
                                ଅମୀୟ ସ୍ମୃତିର ପାଦ ଚିହ୍ନ ଥାପି ଯାଇଛି। ସେଦିନର ଶୁଭ ଲଗ୍ନରେ ଏ ଅଞ୍ଚଳବାସୀ ମା’ଙ୍କର ପୂଜାର୍ଚନା ଓ ପୂଣ୍ୟ ପାର୍ବଣ ନିମିତ ଭିତ୍ତିଭୂମି ସ୍ଥାପନ କରିଥିଲେ।<br /> ଶଙ୍ଖଚିଲାରେ ମା' ହିଙ୍ଗୁଳା ଦେବୀଙ୍କ ଆବିର୍ଭାବ ଏକ ଅଲୋକିକ କାହାଣୀ।ଜଗତର ଜନନୀ ବଟ ବୃକ୍ଷ ବାସିନୀ ଦାରୁବ୍ରହ୍ମ
                                ରୂପୀଣୀ ଏ ମା' ହିଙ୍ଗୁଳା ଶଙ୍ଖଚିଲା ଶକ୍ତିପୀଠର ମହିମା ସୂଦରପ୍ରସାରୀ। ଶଙ୍ଖଚିଲା ଅଞ୍ଚଳର ବ୍ୟବସାୟୀମାନେ ଏକତ୍ରିତ ହୋଇ କେଉଁ ଝର ଢେଙ୍କାନାଳ ଆଦି ଓଡ଼ିଶାର ସୁଦୂର ବନ ପର୍ବତ ଘେରା ଦୁର୍ଗମ ଅଞ୍ଚଳକୁ ଯାଇ ଖାଡୁ ବଳଦ ସାହାଯ୍ୟରେ ଜିନିଷ ପତ୍ର ନେବା ଆଣିବା କରି ବ୍ୟବସାୟ କରୁଥିଲେ।
                                ଶଙ୍ଖଚିଲା ମଠ ପାଟଣାର ଜନୈକ ମହାଜନ ହରି ସାହୁ ସେ ସମୟରେ ଢେଙ୍କାନାଳ ଅଞ୍ଚଳରେ ଖାଡୁ ବଳଦ ସାହାଯ୍ୟରେ ବ୍ୟବସାୟ କରୁଥିଲେ। ଢେଙ୍କାନାଳ ତାଳଚେର ହିଙ୍ଗୁଳା ପୀଠର ସେ ଜଣେ ପରମ ଭକ୍ତ ଥିଲେ। ପ୍ରତିଥର ବ୍ୟବସାୟ କରିବା ସମୟରେ ମା'ଙ୍କ ଦର୍ଶନ କରି କି ଆସୁଥିଲେ। ତାଙ୍କରି ଖାଡୁ ବଳଦ
                                ମାଧ୍ୟମରେ ଶଙ୍ଖଚିଲାକୁ ମା' ହିଙ୍ଗୁଳାଙ୍କ ଶୁଭାଗମନ ହୋଇଥିଲା।ଏ ଏକ ଅଦ୍ଭୁତ ଘଟଣା। ହରି ସାହୁ ମହାଜନ ପ୍ରଥମ ଖାଡୁ ବଳଦର ପହ୍ଲାଣ ପାଣ୍ଠି ରଙ୍ଗ ପାଟ ବସ୍ତ୍ର ଉପରେ ଅଗ୍ନି ବଳୟର ଝଲକ ବିରାଜମାନ କରିଯାଇଥିଲା।ବାଟ ସାରା ବଳଦ ବେକର ଟିପା ଘଣ୍ଟ ଶବ୍ଦ,ଘଣ୍ଟି ଘାଗୁଡିର ଲହର ସହ ଝୁଣା
                                ଗୋଗଳ ର ସୁବାସ ହଳଦୀ ଗନ୍ଧ ବୋଳାର ବାସ୍ନା, ରୁଣୁ ଝୁଣୁ ନୁପୁରର ଝଙ୍କାର ବାଟ ସାରା ତାଳଚେରରୁ ଶଙ୍ଖଚିଲା ପର୍ଯ୍ୟନ୍ତ ଅନୁଭୂତ ହେଉଥିଲା। ହଠାତ୍ ବନ୍ଦ ହୋଇଯାଇଥିଲା ଶଙ୍ଖଚିଲାର ବ୍ରଜ ବାଲିଦାଣ୍ଡର ଏକ ସୁନ୍ଦର ସବୁଜ ବିରାଟ ବଟବୃକ୍ଷ ମୂଳେ। ମହାଜନ ହରି ସାହୁ ଫେରି ଚାହିଁ ଥିଲା
                                ଠାକୁର ପାଟଣା ଗ୍ରାମର କଷ୍ଟି ଦାସ ଲଗାଇ ଥିବା ସେହି ବର ଗଛକୁ। ସବୁ ଶୂନ୍ୟ। ତାଳଚେରର ସେ ମା ହିଙ୍ଗୁଳାର ଆଗ୍ନେୟ ଶକ୍ତି ଦେବୀମୃତ୍ତି ଆକସ୍ମିକ ଉଭାନ ଆଉ ବିରାଜମାନ କରିଗଲେ ଏହି ବଟବୃକ୍ଷ ମଧ୍ୟରେ। କିନ୍ତୁ ବିଧିର ବିଡ଼ମ୍ବନା ଅଲଗା।କି ହରି ସାହୁ କି ଏ ଅଞ୍ଚଳବାସୀ ବୁଝିପାରି
                                ନଥିଲେ ମାତୃଶକ୍ତି ର ଉପସ୍ଥିତି। ମାତୃଶକ୍ତି ସଂସ୍ଥାପନରେ ଅବହେଳା ପ୍ରଦର୍ଶନ ଯୋଗୁଁ ଦୈବୀ ଶକ୍ତିର ବିଡ଼ମ୍ବନା ବ୍ୟକ୍ତ ହୋଇଥିଲା।୧୯୨୯ ମସିହା ବୈଶାଖ ମାସ ୬ ଦିନ ଶୁକ୍ରବାର ମଧ୍ୟାହ୍ନରେ ଜ୍ୱଳାମୟୀ ହିଙ୍ଗୁଳାର ଅଲୋକିକ ଅଗ୍ନିହୁଳା ଶଙ୍ଖଚିଲାର ଖମଣ ଗ୍ରାମ ଠାରୁ ନାଉଡପୁର ପର୍ଯ୍ୟନ୍ତ
                                ସମଗ୍ର ବାର ପାଟଣା ରେ ନିଆଁ ଲାଗି ଯାଇଥିଲା।ଲୁହା ସିନ୍ଧୁକ ରେ ଥିବା ଜିନିଷ କି ପାଣିରେ ପଡ଼ିଥିବା ଜିନିଷ ସବୁ ଜଳି କଳା ଅଙ୍ଗାର ପଡ଼ିଯାଇଥିଲା।ଲୋକେ ଡହଳ ବିକଳ ହେଲେ, କିନ୍ତୁ ଅଲୋକିକ ଦୈବୀ ଶକ୍ତି କଥା କେହି ବୁଝି ପାରିଲେ ନାହିଁ।ତା’ର ପରବର୍ଷ ୧୯୩୦ ମସିହା ବୈଶାଖ ମାସ ୫
                                ଦିନ ଶୁକ୍ରବାର ମଧ୍ୟାହ୍ନରେ ପୁନଃ ବାର ଅଚାନକ ଅଗ୍ନିକାଣ୍ଡ ଘଟି ଶହ ଶହ ଘରଦ୍ୱାର ପୋଡ଼ି ଜଳି ଭସ୍ମୀଭୂତ ହୋଇଗଲା।ଲୋକେ ହା ହତାଶ ଡହଳ ବିକଳ ହେଉଥିବା ବେଳେ କ୍ରନ୍ଦନ ରତ ସନ୍ତାନ ମାନଙ୍କୁ ମା ଭଳି ସାନ୍ତନା ଦେଇଥିଲେ କାଳିସୀ ଲାଗି ଉଦୟ ପାଟଣାର ଚାରୀ ନାମକ ଜଣେ ବୃଦ୍ଧା ମହିଳା।
                                ଦୈବୀ ଶକ୍ତିର ଆବିର୍ଭାବ ସୂଚନା ଓ ତାର ଆରାଧନା ପାଇଁ ପ୍ରବତ୍ତାଇ ଥିଲେ ଲୋକମାନଙ୍କୁ କୀଳିସୀ ମାଧ୍ୟମରେ।ତା ପରେ ପରେ ସେହି ଦିନ ଠାରୁ ଶଙ୍ଖଚିଲାର ହିଙ୍ଗୁଳା ଦେବୀ ପୀଠରେ ଅଦ୍ୟାବଧି ଯଥାରିତୀ ଦେବୀ ପୂଜା ଆରାଧନା ଯଞୋତ୍ସବ ଆଡ଼ମ୍ବର ସହକାରେ ଯଥାରୀତି ପାଳିତ ହୋଇଆସୁଛି। <br /><b>ଯଞ କୁଣ୍ଢେ ସ୍ୱୟଂ ଅଗ୍ନି ହୋଇଲେ ଉଚ୍ଛନ୍ନ, ମାତୃଶକ୍ତି ସଂସ୍ଥା ପନଅପୂର୍ବ ସେ ଯଞୋତ୍ସବ,କେ ଅବା ବର୍ଣିତ ପାରିବ, ଅଭୁଲା ଦେବୀ ର କୀର୍ତୀ ସ୍ମୃତି ମାନ।</b><br />ପ୍ରଥମ
                                ବର୍ଷ ଯଜ୍ଞକୁଣ୍ଡରୁ ଅଗ୍ନି ରୂପରେ ମା ସ୍ୱୟଂ ଆବିର୍ଭୂତା ହୋଇଥିଲେ।ପାଟ ବସ୍ତ୍ର ଯଞ ଉପକରଣ ପଣା ପ୍ରସାଦ ଇତ୍ୟାଦି ଗ୍ରହଣ କରିଥିଲେ। ତଦନୁଯାୟୀ ଅଦ୍ୟାବଧି ଯଞୋତ୍ସବର ମଧ୍ୟମ ଦିବସ ରେ ଦୈବୀ ପଣା ହାଣ୍ଡି ରେ ଉଭା ହୋଇ ଅସଂଖ୍ୟ ଜନଜୀବନ ମାନସ ସାର୍ଥକ କରୁଛନ୍ତି।ଏ ମା ହିଙ୍ଗୁଳା
                                ଚିର ଜାଜୁଲ୍ୟମାନ ଅଗ୍ନି ହୁଳା। ସେ ଜଗତର ଜନନୀ ଅଭୟ ବରଦାୟିନୀ। ସ୍ୱର୍ଣ୍ଣ ମୁକୁଟ ମଣ୍ଡିତା,ବିଲ୍ୱ ମନ୍ଦାର ଶୋଭିତା।ସେ ବଟବୃକ୍ଷ ବାସିନୀ ଦାରୁ ବ୍ରହ୍ମ ରୂପୀଣୀ।ସର୍ବ ମଙ୍ଗଳା ବିଶ୍ୱର ମଙ୍ଗଳ କାରିଣୀ ଅଟନ୍ତି।ସେ ଅଶେଷ କଲ୍ୟାଣ ମୟୀ ଯୁଗମତ୍ ଆନନ୍ଦ ମୟୀ।</p>



                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}