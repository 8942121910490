import React from "react";

export default function Location() {
    return (
        <div>
            {/* <section class="locationhero innerhero">
                <div class="container">
                    <div class="col-md-8 col-sm-6 col-xs-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>Maa Hingula Temple Location - sankhachila, jajpur, odisha </h1>
                        </div>
                    </div>
                </div>
            </section> */}
            


            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>Maa Hingula Temple Location - sankhachila, jajpur, odisha </h1>
                        </div>
                            <nav class="breadcrumb breadcrumb-custom">
                                <a class="breadcrumb-item" href="/">Home</a>
                                <span class="breadcrumb-item active">Location</span>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section class="location innersub">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="carousel-caption animateOnScroll fadeInLeft">
                                <div class="subtitle1 left">ଅବସ୍ଥିତି</div>
                                <div class="">
                                    <span class=""><h3>Address</h3>
                                        <p>ମା ହିଙ୍ଗୁଳା ପୀଠ,<br />ଶଙ୍ଖଚିଲା , ଯାଜପୁର, ୭୫୫୦୧୫<br/>ଓଡିଶା</p><br/></span>
                                    <h3>ଗମନାଗମନ</h3>
                                    <h4><strong>ଯାଜପୁର କେନ୍ଦୁଝର ରୋଡ ରେଳ ଷ୍ଟେସନ ତଥା ବ୍ୟାସନଗର ଠାରୁ ୫ କି.ମି ବ୍ୟବଧାନରେ ଶଙ୍ଖଚିଲାରେ ମା’ ହିଙ୍ଗୁଳାଙ୍କ ପୀଠସ୍ଥଳୀ ଅବସ୍ଥିତ। ପୁନଶ୍ଚ ଯାଜପୁର ରୋଡ଼ ଦଳା ଠାରୁ ପୂର୍ତ ବିଭାଗର ଏକ ପିଚୁ ରାସ୍ତା ହିଙ୍ଗୁଳା ପୀଠ ଦେଇ ୧୨ କି.ମି ବ୍ୟାପୀ ହାଟସାହିଟିକର ନିକଟରେ ୫ ନଂ
                                        ଜାତୀୟ ରାଜପଥକୁ ସଂଯୋଗ କରୁଛି।ପ୍ରତ୍ୟେହ ଏହି ରାସ୍ତାରେ ବହୁ ସରକାରୀ ଓ ବେସରକାରୀ ଯାନବାହାନ ଚଳାଚଳ ଉକ୍ତ ପୀଠକୁ ସର୍ବସାଧାରଣଙ୍କ ପାଇଁ ଗମନାଗମନର ଅପୂର୍ବ ସୂଯୋଗ ଆଣିଛି। ମନ୍ଦିର ମାଳିନୀ ନଗର ଭୁବନେଶ୍ଵର ଠାରୁ ୧୦୦କିମି ଏବଂ ତାଳଚେରର ଆଦ୍ୟ ହିଙ୍ଗୁଳାପୀଠ ଠାରୁ
                                        ୧୦୦ କିମି ଦୂରରେ ଅବସ୍ଥିତ ଏହି ଶଙ୍ଖଚିଲାର ମା’ ହିଙ୍ଗୁଳା ପୀଠ। ଯାଜପୁର ର ମା ବିରଜା କ୍ଷେତ୍ର ଠାରୁ ମାତ୍ର ୩୦ କିମି ଏବଂ ଘଟଗାଁ ମା ତାରିଣୀ ପୀଠ ଠାରୁ ୭୦ କିମି ଦୂରରେ ମା ହିଙ୍ଗୁଳା ପୀଠ ଅବସ୍ଥିତ।</strong></h4>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1863.5936679534818!2d86.15273!3d20.9047814!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19511e409925f3%3A0xa3874c8e71621ea9!2sHingula%20Temple%20Sankhchila!5e0!3m2!1sen!2sin!4v1626792531705!5m2!1sen!2sin"
                                        width="100%" height="450" frameborder="0" style={{ border: "0" }} title="Google map" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}