import React from "react";

export default function features(){
    return(
        <div>
 {/* <section class="abouthero innerhero">
        <div class="container">
            <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="inner-caption animated fadeInLeft">
                    <h1 class="special-features">The Special Features of Maa Hingula Temple</h1>
                </div>
            </div>
        </div>
    </section> */}

    <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                 <div class="inner-caption animated fadeInLeft">
                    <h1 class="special-features">The Special Features of Maa Hingula Temple</h1>
                </div>
                    <nav class="breadcrumb breadcrumb-custom">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <a class="breadcrumb-item" href="/about">About</a>
                        <span class="breadcrumb-item active">ପୂଜା ବୈଶିଷ୍ଟ </span>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <section class="drowpdown-innertabs-mob-section about">
        <div class="container text-center">
            <div class="dropdown drowpdown-innertabs-mob">
                <button class="btn btn-primary dropdown-toggle btn-innertab" type="button" data-toggle="dropdown" aria-expanded="true">Features
            	<span class="caret"></span></button>
                <ul class="dropdown-menu">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/history">History</a></li>
                    <li><a href="/architecture">Architecture</a></li>
                    <li><a href="/deities">Deities</a></li>
                    <li><a href="/">Maa Hingula</a></li>
                </ul>
            </div>
        </div>
    </section>

    <section class="about">
        <div class="container">
            <div class="clear"></div>

            <div class="col-xs-12 animateOnScroll fadeInUp">
                <div class="aboutsubinner">
                    <div class="col-md-8 col-md-offset-3">
                        <div class="col-md-3 col-sm-4 col-xs-12" align="center"></div>
                        <div class="col-md-8 col-sm-8 col-xs-12">
                            <h3>ପୂଜା ବୈଶିଷ୍ଟ</h3>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <p class="text-center"><strong>ମା'ଙ୍କ ପାଖରେ ବିଭିନ୍ନ ଯାନ ବାହାନ ପୂଜା, ବିବାହ,ନିର୍ବନ୍ଧ,ବ୍ରତୋପନୟନ ଆଦି କାର୍ଯ୍ୟକ୍ରମ ସମ୍ପାଦିତ ହୋଇଥାଏ।</strong></p>
                    <p class="text-center"><strong>ଏତତ୍ ଭିନ୍ନ ମା'ଙ୍କ ପାଖରେ (୧) ପ୍ରତି ମାସର ପୂର୍ଣ୍ଣିମୀ, ଅମାବାସ୍ୟା, ସଂକ୍ରାନ୍ତି ଓ ଶୁକ୍ଳ ଚତୁର୍ଦ୍ଦଶୀ ତିଥିରେ ସତସଙ୍ଗୀ ମାନଙ୍କ ଦ୍ଵାରା ଧର୍ମ ଆଲୋଚନା ଓ ପ୍ରସାଦ ସେବନ (୨) ଶ୍ରୀ ଶ୍ରୀ ଗଣେଶ  ଚତୁର୍ଥୀ ଠାରୁ ୯ ଦିନ ବ୍ୟାପୀ ଶ୍ରୀ ରାମ ଚରିତ ମାନସ ପାରାୟଣ ଓ ପ୍ରସାଦ ବିତରଣ (୩) ମାର୍ଗଶୀର ମାସ ପ୍ରତ୍ୟେକ ଗୁରୁବାର ଲକ୍ଷ୍ମୀ ପୂଜା ଓ ଆଲୋଚନା ଅନୁଷ୍ଠିତ ହୋଇଥାଏ।</strong></p>
                    <div class="col-md-8 col-md-offset-3">
                        <div class="col-md-8 col-sm-8 col-xs-12">
                            <h3>ମଧ୍ୟାହ୍ନ ନିତୀ ଓ ରାତ୍ର ଖେଚୁଡ଼ି ଭୋଗ ଉପଲବ୍ଧ</h3>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <p class="text-center"><strong>ପ୍ରତ୍ୟେହ ମା'ଙ୍କ ନିକଟରେ ହେଉଥିବା ମଧ୍ୟାହ୍ନ ନିତୀ ଓ ରାତ୍ର ଖେଚୁଡ଼ି ଭୋଗ ସେବନ କରିବା ପାଇଁ ଚାହିଂଲେ ସେହିଦିନ ସକାଳ ୮  ଘ ମଧ୍ୟରେ ନିତୀ ର ଦେୟ ଦେଇ ବରାଦ କରିପାରିବେ। ଯେଉଁ ଶ୍ରଦ୍ଧାଳୁ ଭକ୍ତମାନେ ୧୦୦୦.୦୦ (ଏକ ହଜାର ଟଙ୍କା) ବା ତଧୃତ ଅର୍ଥ  ମା ହିଙ୍ଗୁଳାଙ୍କ ନିତୀ ଖେଚୁଡ଼ି  ବିଭାଗ ରେ ସ୍ଥାୟୀ ଦାନ କରିବେ, ତାଙ୍କୁ ପ୍ରତି ବର୍ଷ  ଏକ ନିର୍ଧାରିତ ( ଜନ୍ମ, ବିବାହ ବାର୍ଷିକ କିମ୍ବା ବାର୍ଷିକ ଶ୍ରାଦ୍ଧ) ଦିବସ ରେ ଏକ ନିତୀ ବା ଖେଚୁଡ଼ି ଭୋଗ ପାରୂଶ ଆକାରରେ ପ୍ରଦାନ କରାଯିବ।</strong></p>
                </div>
            </div>


        </div>
    </section>
        </div>
    )
}