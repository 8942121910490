import React from "react";


export default function Home() {
    return (
        <div>
            <section class="hero">
                <div class="container">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <h1 style={{ textAlign: "center" }}>ମା' ହିଙ୍ଗୁଳା ପୀଠ ଶଙ୍ଖଚିଲା</h1>
                        <div class="carousel-caption animated fadeInLeft">
                            <h3>ଯା ଦେବୀ ଅଗ୍ନି ସ୍ବରୂପା କନକ ବରଦା ତାପ ତ୍ରୟ ଧ୍ଧଂସିନୀଂ ସ୍ବର୍ଷି ମୁକୁଟ ମଞ୍ଥିତା ଜଗତ୍‌ ଜନନୀ ବଟବୃକ୍ଷ ବାସିନୀଂ । ବିଲ୍ମ ମନ୍ଦାର ଶୋଭିତା ସୁଯଜ୍ଞ ବିଗ୍ରହା ସର୍ବବିଶ୍ପ ବନ୍ଦିନୀଂ ସା ଦେବୀ ସର୍ବ ମଙ୍ଗଂଳାଂ ହିଙ୍ଗୁଳାଂ ନମାମି ଦାରୁ ବ୍ରହ୍ମ ରୂପିଣୀଂ ॥</h3>
                            <div class="knowmore left"><a href="/location">Plan to Visit</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about">
                <div class="container">
                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding animateOnScroll fadeInUp">
                        <img src={process.env.PUBLIC_URL + "assets/h-temp/img/welc-h.png"} class="img-responsive" alt="Maa Hingula Temple " title="Maa Hingula Temple Sankhachila" />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 animateOnScroll fadeInUp">
                        <div class="subtitle">Welcome to</div>
                        <h1>Maa Hingula Temple Sankhachila </h1>
                        <p>ମା ହିଙ୍ଗୁଳା ଙ୍କ ଆବିର୍ଭାବ ୧୯୨୯ ଏପ୍ରିଲ ୪ ତାରିଖ ଶୁକ୍ରବାର ଜ୍ୱଲାମୟୀ ଅଗ୍ନି ରୂପରେ ଶଙ୍ଖଚିଲା ର ବାଲିଦାଣ୍ଡ ର ବଟବୃକ୍ଷ ରେ ହୋଇଥିଲେ |</p>
                        <div class="knowmore"><a href="/about">Know More</a></div>
                    </div>

                    <div class="clear"></div><br /><br /><br /><br />

                    <div class="col-md-3 col-sm-12 col-xs-12 animateOnScroll fadeInUp" id="adj">
                        <a href="/history">
                            <div class="aboutsub" align="center">
                                <div><img src={process.env.PUBLIC_URL + "assets/h-temp/img/icon-history-home.png"} alt="Temple History" title="Temple History 
"/><br /></div>
                                <h3>ଇତିହାସ </h3>

                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12 animateOnScroll fadeInUp" id="adj">
                        <a href="/architecture">
                            <div class="aboutsub" align="center">
                                <div><img src={process.env.PUBLIC_URL + "assets/h-temp/img/icon-temple-home.png"} alt="Maa Hingula Temple" title="Maa Hingula Temple" /><br /></div>
                                <h3>ମନ୍ଦିର ନିର୍ମାଣ</h3>

                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12 animateOnScroll fadeInUp" id="adj">
                        <a href="/deities">
                            <div class="aboutsub" align="center">
                                <div><img src={process.env.PUBLIC_URL + "assets/h-temp/img/icon-deities-home.png"} alt="Maa Hingula Temple Deities" title="Maa Hingula  Temple Deities" /><br /></div>
                                <h3>ପୂଜା ଓ ପ୍ରାର୍ଥନା  </h3>

                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12 animateOnScroll fadeInUp" id="adj">
                        <a href="/special-features">
                            <div class="aboutsub" align="center">
                                <div><img src={process.env.PUBLIC_URL + "assets/h-temp/img/icon-features-home.png"} alt="Features of Temple" title="Features of Temple" /><br /></div>
                                <h3>ପୂଜା ବୈଶିଷ୍ଟ </h3>

                            </div>
                        </a>
                    </div>

                    <div class="col-md-3 col-sm-12 col-xs-12 animateOnScroll fadeInUp" id="adj">
                        <a href="/donation">
                            <div class="aboutsub" align="center">
                                <div><img src={process.env.PUBLIC_URL + "assets/h-temp/img/lord.png"} alt="Maa Hingula" title="Maa Hingula" /><br /></div>
                                <h3>ଦାନ </h3>
                            </div>
                        </a>
                    </div>

                </div>
            </section>

            <section class="pooja">
                <div class="container">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div id="myCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="item active">
                                    <div class="carousel-caption animateOnScroll fadeInRight">
                                        <div class="subtitle1 left">
                                            <font size="+2">ପୂଜା ବିଧି </font>
                                        </div>
                                        <h3>ମା ହିଙ୍ଗୁଳାଙ୍କ ନିକଟରେ ମାନସିକ ରଖି ପୂଜାର୍ଚ୍ଚନା କଲେ ଭକ୍ତଙ୍କ ମନସ୍କାମନା ପୂର୍ଣ୍ଣ ହୁଏ।</h3>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="carousel-caption animateOnScroll fadeInRight">
                                        <div class="subtitle1 left">
                                            <font size="+2">ପୂଜା ବିଧି </font>
                                        </div>
                                        <h3>ମା ହିଙ୍ଗୁଳାଙ୍କ ନିକଟରେ ମାନସିକ ରଖି ପୂଜାର୍ଚ୍ଚନା କଲେ ଭକ୍ତଙ୍କ ମନସ୍କାମନା ପୂର୍ଣ୍ଣ ହୁଏ।</h3>

                                    </div>

                                </div>
                                <div class="knowmore2 left"><a href="/pooja">Know More</a></div>
                            </div>

                        </div>

                    </div>
                </div>


            </section>


            <section class="festival">
                <div class="container">
                    <div class="col-md-6 col-sm-6 col-xs-12 fright">
                        <div class="carousel-caption animateOnScroll fadeInRight">
                            <div class="subtitle1 left">Maa Hingula Temple,Sankhachila Festival</div>
                            <h2 style={{ marginBottom: "0", color: "#fff" }} class="text-left">ହିଙ୍ଗୁଳା ପୂଜା ପାର୍ବଣ </h2>
                            <h3>ହିଙ୍ଗୁଳା ପୀଠର ମୁଖ୍ୟ ଆକର୍ଷଣ ହେଉଛି ୭ ଦିନ ବ୍ୟାପୀ ବିଶ୍ଵଶାନ୍ତି ମହାଯଜ୍ଞ ମହୋତ୍ସବ। ଯେଉଁ ଉତ୍ସବକୁ ଓଡ଼ିଶା ତଥା ଓଡ଼ିଶା ବାହାର ଅଗଣିତ ଭକ୍ତ ମାନେ ଉତ୍କଣ୍ଠାର ସହିତ ଗୋଟିଏ ବର୍ଷ ପ୍ରତିକ୍ଷା କରିଥାନ୍ତି। ତାହା ପ୍ରତି ବର୍ଷ ଚୈତ୍ର ମାସ ଚତୁର୍ଦ୍ଦଶୀ ତିଥିରେ ଆରମ୍ଭ ହୋଇଥାଏ।</h3>
                            <div class="knowmore left"><a href="/festivals">Know More</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="gallerydiv clear">
                <div class="container">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="carousel-caption animateOnScroll fadeInUp">
                            <div class="subtitle2">Hingula Temple Photos</div>
                            {/* <h3></h3> */}
                            <div class="knowmore2"><a href="/gallery">Know More</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="location location-home">
                <div class="container">
                    <div class="col-md-6 col-sm-6 col-xs-12 fright">
                        <div class="carousel-caption animateOnScroll fadeInRight">
                            <div class="subtitle1 left">ମା ହିଙ୍ଗୁଳା ପୀଠ ଅବସ୍ଥିତି ଓ ଗମନାଗମନ </div>
                            <h3>ଯାଜପୁର କେନ୍ଦୁଝର ରୋଡ ରେଳ ଷ୍ଟେସନ ତଥା ବ୍ୟାସନଗର ଠାରୁ ୫ କି.ମି ବ୍ୟବଧାନରେ ଶଙ୍ଖଚିଲାରେ ମା’ ଗମନାଗମନ</h3>
                            <div class="knowmore left"><a href="/location">Know More</a></div>
                        </div>
                    </div>

                </div>

            </section>

            <div class="iframe-container">
            <iframe width="560%" height="315%" src="https://www.youtube.com/embed/wRQziQVUrnI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
            {/* <Iframe url="http://www.youtube.com/embed/xDMP3i36naA"
           
            width="100%"
            id="myId"
            
            height="1000%"
            styles={{height: "50px"}}/>
                </div> */}


        </div>
    )
}