import React from "react";

export default function Gallery(){
    return(
        <div>
            {/* <section class="galleryhero innerhero">
        <div class="container">
            <div class="col-md-9 col-sm-6 col-xs-12">
                <div class="inner-caption animated fadeInLeft">
                    <h1>Maa Hingula Temple sankhachila Photo & Video Gallery </h1>
                </div>
            </div>
        </div>
    </section> */}
    <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                 <div class="inner-caption animated fadeInLeft">
                    <h1>Maa Hingula Temple sankhachila Photo & Video Gallery </h1>
                </div>
                    <nav class="breadcrumb breadcrumb-custom">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active">Gallery</span>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <section class="gallerydiv1">
        <div class="container">
            <div class="row">
                <div class="col-md-12 album">
                    <h2 class="text-center">
                        Photo Album
                    </h2>
                </div>
                <div class="col-md-3 col-xs-6">
                    <div class="gallery-box gallerybox-main img-thumbnail">
                        <a  href="/maahingula-photos"><img src={process.env.PUBLIC_URL + "assets/h-temp/maa.jpeg"} alt="Maa hingula sankhachila photos" title="Maahingula Temple sankhachila image of Maahingula" class="img-responsive"/>
                            <p>
                               Click To More Maa Hingula Photos
                            </p>
                        </a>
                    </div>
                </div>
                <div class="col-md-3 col-xs-6">
                    <div class="gallery-box gallerybox-main img-thumbnail">
                        <a href="/temple-photos"><img src={process.env.PUBLIC_URL + "assets/h-temp/t-gallery.jpeg"} alt="Maahingula sankhachila temple photos" title="Maahingula temple - located in Sankhachila, Jajpur"  class="img-responsive"/>
                            <p>
                          Click To More Temple Photos 
                            </p>
                            
                        </a>
                    </div>
                </div>
                <div class="col-md-3 col-xs-6">
                    <div class="gallery-box gallerybox-main img-thumbnail">
                        <a href="/festival-photos"><img src={process.env.PUBLIC_URL + "assets/h-temp/f-gallery.jpeg"} alt="Maahingula temple sankhachila festival celebration" title="Maahingula festival celebration at Sankhachila, jajpur"  class="img-responsive"/>
                            <p>
                            Click To More Temple Festivals & pooja Photos 
                            </p>
                        </a>
                    </div>
                </div>
                {/* <div class="col-md-3 col-xs-6">
                    <div class="gallery-box gallerybox-main img-thumbnail">
                        <a href="srirangam-temple-pooja-photos.html"><img src={process.env.PUBLIC_URL + "assets/h-temp/maa.jpeg"} alt="Videos of Maahingula temple sankhachila" title="All videos " class="img-responsive"/>
                            <p>
                              Click To More Maa hingula sankhachila videos
                            </p>
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    </section>

        </div>
    )
}