import React from "react";


export default function NavBar() {
    return (
       <div>

<header id="navbar">

<nav class="navbar navbar-default navbar-fixed-top" role="navigation">
    <div class="container">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
            <a class="navbar-brand navbar-brand-centered" href="./" > <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Hingula Temple Logo" title="Hingula Logo" class="img-responsive"/></a>

        </div>

        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav">
            <li><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/pooja">Pooja Details</a></li>
                <li><a href="/festivals">Festivals</a></li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li><a href="/gallery">Gallery</a></li>
                <li><a href="/location">Location</a></li>  
                <li><a href="/donation">Donation</a></li>
                <li><a href="/management">Management</a></li>

            </ul>
        </div>
        {/* <!-- /.navbar-collapse --> */}
    </div>
</nav>
</header>



       </div>
    )
}