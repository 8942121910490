import React from "react";

export default function Pooja() {
    return (
        <div>
            {/* <section class="poojahero innerhero">
                <div class="container">
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>Maa Hingula Temple Darshan Timings & Pooja Details</h1>
                        </div>
                    </div>
                </div>
            </section> */}

            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>Maa Hingula Temple Darshan Timings & Pooja Details</h1>
                        </div>
                            <nav class="breadcrumb breadcrumb-custom">
                                <a class="breadcrumb-item" href="/">Home</a>
                                <span class="breadcrumb-item active">Pooja Details</span>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pooja innersub">
                <div class="container">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div id="myCarousel" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="item active">
                                    <div class="carousel-caption animateOnScroll fadeInRight">
                                        <div class="subtitle1 left">ପୂଜା ବିଧି</div>

                                        <h3>ମା ହିଙ୍ଗୁଳାଙ୍କ ନିକଟରେ ମାନସିକ ରଖି ପୂଜାର୍ଚ୍ଚନା କଲେ ଭକ୍ତଙ୍କ ମନସ୍କାମନା ପୂର୍ଣ୍ଣ ହୁଏ।</h3>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="carousel-caption animateOnScroll fadeInRight">
                                        <div class="subtitle1 left">ପୂଜା ବିଧି</div>
                                        <h3>ମା ହିଙ୍ଗୁଳାଙ୍କ ନିକଟରେ ମାନସିକ ରଖି ପୂଜାର୍ଚ୍ଚନା କଲେ ଭକ୍ତଙ୍କ ମନସ୍କାମନା ପୂର୍ଣ୍ଣ ହୁଏ।</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="poojadetails mobile animateOnScroll fadeInUp">
                <div class="poojadetails">
                    <h3>ପୂଜା ସମୟ </h3>
                    
                    <div class="table-responsive">
                        <table width="100%" class="table table-borderless pooja-table" border="0" cellspacing="1" cellpadding="1">
                            <tbody>
                                <tr>
                                    <td width="" align="left" valign="middle"><strong>ମଙ୍ଗଳ ଆଳତି</strong> </td>
                                    <td width="" align="left" valign="middle"><strong>ସକାଳ ୫.୦ରୁ ୬.୦ </strong></td>

                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ସ୍ନାନ ପୂଜା</strong> </td>
                                    <td align="left" valign="middle"><strong>ସକାଳ ୬.୦ ରୁ ୬.୪୫ </strong></td>

                                </tr>

                                <tr>
                                    <td align="left" valign="middle"><strong>ବାଲ୍ୟ ଭୋଗ</strong></td>
                                    <td align="left" valign="middle"><strong>ସକାଳ ୬.୪୫ ରୁ ୭.୦</strong></td>
                                </tr>

                                <tr>
                                    <td align="left" valign="middle"><strong>ପ୍ରାର୍ଥନା</strong> </td>
                                    <td align="left" valign="middle"><strong>ସକାଳ ୭.୦ରୁ ୭.୧୫ </strong></td>
                                </tr>

                                <tr>
                                    <td align="left" valign="middle"><strong>ବଡ଼ସିଂହାର </strong></td>
                                    <td align="left" valign="middle"><strong>ସକାଳ ୭.୧୫ ରୁ ୯.୦</strong></td>


                                </tr>

                                <tr>
                                    <td align="left" valign="middle"><strong>ହୋମ</strong> </td>
                                    <td align="left" valign="middle"><strong>ସକାଳ ୯.୦ ରୁ ୧୦.୦ </strong></td>


                                </tr>

                                <tr>
                                    <td align="left" valign="middle"><strong>ଧାତୁ ଦ୍ରବ୍ୟ ଅର୍ପଣ</strong></td>
                                    <td align="left" valign="middle"><strong>ସକାଳ ୧୦.୦ ରୁ ୧୨.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ଅନ୍ନ ଭୋଗ </strong> </td>
                                    <td align="left" valign="middle"><strong>ମଧ୍ୟାହ୍ନ ୧୨.୦ ରୁ ୧.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ମଧ୍ୟାହ୍ନ ପହୁଡ଼</strong> </td>
                                    <td align="left" valign="middle"><strong>ଦିନ ୧.୦ ରୁ ୩.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ବଡ଼ସିଂହାର</strong> </td>
                                    <td align="left" valign="middle"><strong>ଅପରାହ୍ନ ୩.୦ ରୁ ୪.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ହୋମ</strong> </td>
                                    <td align="left" valign="middle"><strong>ଅପରାହ୍ନ ୪.୦ ରୁ ୫.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ଧାତୁ ଦ୍ରବ୍ୟ ଅର୍ପଣ</strong> </td>
                                    <td align="left" valign="middle"><strong>ଅପରାହ୍ନ ୫.୦ ରୁ ୬.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ସନ୍ଧ୍ୟା ଆଳତି</strong> </td>
                                    <td align="left" valign="middle"><strong>ସନ୍ଧ୍ୟା ୬.୦ ରୁ ୭.୦</strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ଭଜନ କୀର୍ତ୍ତନ</strong> </td>
                                    <td align="left" valign="middle"><strong>ରାତ୍ର ୭.୦ ରୁ ୭.୩୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ସତସଙ୍ଗ</strong> </td>
                                    <td align="left" valign="middle"><strong>ରାତ୍ର ୭.୩୦ ରୁ ୮.୩୦</strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ଖେଚୁଡ଼ି ଭୋଗ</strong> </td>
                                    <td align="left" valign="middle"><strong>ରାତ୍ର ୮.୩୦ ରୁ ୯.୦ </strong></td>


                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ରାତ୍ର ପହୁଡ଼</strong> </td>
                                    <td align="left" valign="middle"><strong>ରାତ୍ର ୯.୦  </strong></td>


                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <br />

                    <h3>ଦର୍ଶନ ସମୟ </h3>
                    <div class="table-responsive">
                        <table width="100%" class="table table-borderless pooja-table" border="0" cellspacing="1" cellpadding="1">
                            <tbody>
                                <tr>
                                    <td width="" align="left" valign="middle"><strong>ସକାଳ ୦୬.୦୦ ରୁ ମଧ୍ୟାହ୍ନ ୧୨.୩୦ ଯାଏଁ |</strong> </td>
                                </tr>
                                <tr>
                                    <td align="left" valign="middle"><strong>ସନ୍ଧ୍ୟା ୦୪.୦୦ ରୁ ରାତ୍ର ୦୮.୩୦ ଯାଏଁ |</strong> </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>

                    <br />


                </div>
            </section>
        </div >
    )
}