import React from "react";

export default function History(){
    return(
        <div>
            


    {/* <section class="abouthero innerhero">
        <div class="container">
            <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="inner-caption animated fadeInLeft">
                    <h1>A Brief History of The Maa Hingula Temple</h1>
                </div>
            </div>
        </div>
    </section> */}

    <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                <div class="inner-caption animated fadeInLeft">
                    <h1>A Brief History of The Maa Hingula Temple</h1>
                </div>
                    <nav class="breadcrumb breadcrumb-custom">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <a class="breadcrumb-item" href="/about">About</a>
                        <span class="breadcrumb-item active">History</span>
                    </nav>
                </div>
            </div>
        </div>
    </section>

    <section class="drowpdown-innertabs-mob-section about">
        <div class="container text-center">
            <div class="dropdown drowpdown-innertabs-mob">
                <button class="btn btn-primary dropdown-toggle btn-innertab" type="button" data-toggle="dropdown" aria-expanded="true">History
            	<span class="caret"></span></button>
                <ul class="dropdown-menu">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/architecture">Architecture</a></li>
                    <li><a href="/deities">Deities</a></li>
                    <li><a href="/special-features">Features</a></li>
                    <li><a href="/">Maa Hingula</a></li>
                </ul>
            </div>
        </div>
    </section>

    <section class="about">
        <div class="container">
            <div class="clear"></div>

            <div class="col-xs-12 animateOnScroll fadeInUp">
                <div class="aboutsubinner">
                    <div class="col-md-5 col-md-offset-4">
                        <div class="col-md-5 col-sm-4 col-xs-12" align="center"><img src={process.env.PUBLIC_URL + "assets/images/icon-history.png"} class="img-responsive" alt="Temple History" title="Temple History"/><br/></div>
                        <div class="col-md-6 col-sm-8 col-xs-12">
                            <h3>ଇତିହାସ</h3>
                        </div>
                        <h3>ଧରାଧାମରେ ମା ହିଙ୍ଗୁଳାଙ୍କ ସତ୍ତା </h3>
                    </div>
                    <div class="clear"></div>
                    <p class="text-center">ମା' ହିଙ୍ଗୁଳା ପୀଠ ଶଙ୍ଖଚିଲା
                        <a href="/"> ଶାକ୍ତ ଧର୍ମର ମୌଳିକ ଚିନ୍ତାଧାରା ମାତୃ ଉପାସନା । ତେଣୁ ମଣିଷ ସର୍ବ ପ୍ରଥମେ ମାତୃ ସତ୍ତାକୁ କଳ୍ପନା କରିଛି ପ୍ରକୃତିର ଛତ୍ରେ ଛତ୍ରେ । ସେ ପ୍ରକୃତିକୁ ଶକ୍ତି ଭାବରେ ଆରାଧନା କରିଛି । ଏହି ପ୍ରକୃତି ରୂପିଣୀ ଶକ୍ତି ଆରାଧନାରୁ ଜଗତ୍‌ଧାତ୍ରୀ ସର୍ବଶକ୍ତିମୟୀ ଆଦିମାତାର
                        କଳ୍ପନା ବୋଲି ଅନୁମିତ ହୁଏ । ଏଥରୁ ସ୍ପଷ୍ଟ ଯେ ଶାକ୍ତ ଧର୍ମର ମୌଳିକ ଚିନ୍ତାଧାରା ନିଶ୍ଚିତ ଭାବେ ପ୍ରାକ୍ଵବୈଦିକ ।</a> 
                    </p>
                    <p class="text-center">ଏହି ପରିପ୍ରେକ୍ଷୀରେ ମା” ହିଙ୍ଗୁଳା ତନ୍ତ୍ରୋକ୍ତ ଶାଦର ଦେବତା । ସେ ତେଜସ୍ଵରୂପା, ଅଗ୍ନିରୂପା, ଜ୍ବାଳାମୟୀ | ସେ ଅଗ୍ନିହୁଳା । ଅଗ୍ନି ଦେବତାଙ୍କ ନାରୀ ଶକ୍ତି । ତେଣୁ ଅଗ୍ନି ଦେବତାଙ୍କ ସନ୍ତୋଷ ବିଧାନ ନିମନ୍ତେ ପ୍ରତିବର୍ଷ ହିଜ୍ଗଂଳାପୀଠ ମାନଙ୍କରେ ଝାମୁ ଯାତ୍ରା ପାଳିତ ହୁଏ ।
                        ପୁରାଣ ବର୍ଣ୍ଣନା ଅନୁସାରେ ଶିବଙ୍କ ଦକ୍ଷ ଯଜ୍ଞ ରେ ସତୀଙ୍କ ଦଗ୍‌ଧ ଶରୀରକୁ କାନ୍ଧରେ ଧରି ଗଲାବେଳେ ସତୀଙ୍କ ଶରୀରର ବିଭିନ୍ନ ଅଂଶ ପଡ଼ିବା ସ୍ଥାନ ଗୁଡିକ ଶକ୍ତି ପୀଠରେ ପରିଣତ ହେଲା । ଏହିପରି ସମଗ୍ର ଭାରତ ବର୍ଷରେ ଏକାବନ ଗୋଟି ପକ୍ଷାନ୍ତରେଏକଶତ ଅ।ଠଗୋଟି ପୀଠ ରହିଥବାର ପ୍ରମାଣ
                        ରହିଛି । ତନ୍ମଧ୍ଯରୁ ହିଙ୍ଗୁଳା ପୀଠ ପ୍ରଥମ ପୀଠ ।
                    </p>
                    <p class="text-center">ଶକ୍ତିପୂଜା ବା ଆରାଧନା ଏକ ପ୍ରାଚୀନ ପରଂପରା । ସମଗ୍ର ଭାରତବର୍ଷରେ ଏ ଧାର। ପ୍ରଚଳିତ । ଶକ୍ତିବିନା ଜଗତର ସୃଷ୍ଟି ଅସମ୍ଭବ । ଶକ୍ତି ବିହିନ ଶିବ ଶବ ସହିତ ସମାନ । ଶକ୍ତିଯୁକ୍ତ ହୋଇଗଲେ ଶିବ ହୋଇଯାନ୍ତି ମଙ୍ଗଳମୟ । ତେଣୁ ପୃଥବୀକୁ ସୌନ୍ଦର୍ଯ୍ୟମୟୀ କରି ଗଢ଼ିତୋଳିବା ପାଇଁ ଶକ୍ତିଙ୍କର
                        ଆରାଧନା ଏକାନ୍ତ ଅପରିହାର୍ଯ୍ୟ ।
                    </p>
                    <p class="text-center">ସର୍ବୋପରି ସାରଳା ଦାସ ତାଙ୍କର ସମସ୍ତ ରଚନା ମଧ୍ଯରେ ହିଙ୍ଗୁଳାଙ୍କ ସିନ୍ଧପାକର ଅଧୂକାରିଣୀ ଭାବେ ବର୍ଣ୍ଣନା କରିଛନ୍ତି । ସଭା ପର୍ବରେ ଭୀମ ରାଜସ୍ଵୟ ଯଜ୍ଞ ପ୍ରସଙ୍ଗରେ କାଉଁରୀ ଦେଶକୁ ଯାଇ କାମପାଳ ରାଜାଙ୍କ ସହ ଯୁଦ୍ଧ ସମୟରେ କାମାକ୍ଷୀଙ୍କ ନିର୍ଦ୍ଦେଶରେ କାମପାଳ ଯୁଦ୍ଧରୁ ନିବୂତ୍ତ
                        ହୋଇଛନ୍ତି । କାମାକ୍ଷୀ ଦେବୀ ଭୀମଙ୍କୁ କାମ୍ୟକ ମନ୍ତ୍ର ପ୍ରଦାନ ସଙ୍ଗେ ସଙ୍ଗେ “ କୁଢ଼ୋଇକ” ଅନ୍ନରେ ଲକ୍ଷ ଲକ୍ଷ ସୈନ୍ଯଙ୍କ କ୍ଷୁଧା ମେଣ୍ଟାଇଥିଲେ । କାମାକ୍ଷୀ ଦେବୀ ନିଜେ ହିଙ୍ଗୁଳାଙ୍କ ଦୂହିତା ବୋଲି କହି ରାଜସ୍ଵୟ ଯଜ୍ଞରେ ସାହାଯ୍ୟ ନିମିତ୍ତ ସ୍ତମ୍ଭନଃ ଉଲ୍ଲକ ପ୍ରଭୂତି
                        ବିଦ୍ୟା ପ୍ରଦାନ କରିଥିଲେ |</p>
                    <p class="text-center">ଧର୍ମରାଜ ଯୁଧିଷ୍ଠିରଙ୍କ ରାଜସୂୟ ଯଜ୍ଞର ପ୍ରସ୍ତୁତି ପର୍ବ ଚାଲିଥାଏ । ଦେଶର ସମସ୍ତ ରାଜା ମାନଙ୍କୁ ନିମନ୍ତ୍ରଣ ଉଦ୍ଦେଶ୍ୟରେ ଚାରିଭାଇ ଭୀମ, ଅର୍ଜୁନ , ନକୁଳ, ସହଦେବ ଏକ ଏକ ଦିଗକୁ ଆଗମନ କରିଥିଲେ | ଅର୍ଜୁନ ଭତ୍ତର ଦିଗକୁ ଯାଇ ରଣସ୍ତମ୍ବ ରାଜ୍ୟରେ ପ୍ରବେଶ କରନ୍ତେ ମହା ପରାକ୍ରମୀ
                        ରାଜା ବୀରବରଙ୍କ ସୈନ୍ଯ ସହ ଯୁଦ୍ଧ ହୋଇଥିଲା । ରଣସ୍ତମ୍ଭ ଗଡ଼ର ଅଧୂଷ୍ଠାତ୍ରୀ ଦେବୀ ମା” ହିଙ୍ଗୁଳାଙ୍କ ମହିମା ଅବଗତ ପରେ ମାଂଙ୍କୁ ଦର୍ଶନ ଓ ଧ୍ଯାନ କରି ଯୁଦ୍ଧରେ ଜୟଲାଭ କରିଥିଲେ । ଅର୍ଜୁନଙ୍କ ଦର୍ଶନରେ ମା” ସନ୍ତୁଷ୍ଟ ହୋଇ ଅର୍ଜୁନଙ୍କୁ ବର ପ୍ରଦାନ କରି କହିଥିଲେ କି, ରାଜସୂୟ
                        ଯଜ୍ଞର ସମସ୍ତ ସିଦ୍ଧ ପାକ ନିମନ୍ତେ ନିଜେ ମା' ହିଙ୍ଗୁଳା ପରୋକ୍ଷ ସଂପାଦନ କରିବାକୁ ଅଭୟ ପ୍ରଦାନ କରିଥିଲେ ।
                    </p>
                    <p class="text-center">ଅଗ୍ନିରୂପା ମା” ହିଙ୍ଗୁଳା ଅଗ୍ନିରୂପେ ପ୍ରକଟିତ ଏହା ସୁସ୍ପଷ୍ଟ । ଭାରତ ଓଡିଶା ତଥା ଓଡିଆ ଜାତିର ପ୍ରାଣକେନ୍ଦ୍ର ଶ୍ରୀକ୍ଷେତ୍ରର ଭୌଗଳିକ ସ୍ଥିତିକୁ ଅନୁଧ୍ୟାନ କଲେ ଜଣାଯାଏ ଯେ, ଭାରତର ୫୨ ଗୋଟି ଶକ୍ତି ପୀଠ ମଧ୍ଯରେ ବିରଜା କ୍ଷେତ୍ର ହିଁ ସର୍ବ ପୁରାତନ ଶକ୍ତିପୀଠ ଓ ବିମଳା କ୍ଷେତ୍ର
                        ହିଁ ସର୍ବଶ୍ରେଷ୍ଠ ଶକ୍ତି ପୀଠ ଭାବେ ପୁରାଣ ସାହିତ୍ୟରେ ମାନ୍ୟତା ଳାଭ କରିଛି । ଭାରତର ଅଗ୍ନିକୋଣରେ ଓଡିଶା ଅବସ୍ଥିତ । ଓଡିଶାର ଅଗ୍ନି କୋଣରେ ନୀଳାଚଳ ଧାମ ପୁରୀ । ପୁରୀ ଶ୍ରୀ ମନ୍ଦିରର ଅଗ୍ନିକୋଣରେ ରୋଷ ଶାଳା । ରୋଷ ଶାଳାର ଅଗ୍ନି କୋଣରେ ମା ହିଙ୍ଗୁଳା କେଉଁ ପ୍ରାଚିନ କାଳରୁ
                        ପୂଜା ପାଇ ଆସୁଛନ୍ତି । କିମ୍ବଦନ୍ତୀ କହେ, ରାଣୀ ଗୁଣ୍ଡିଚା ଜଗନ୍ନାଥଙ୍କ ଷାଠିଏ ପଉଟି ଭୋଗ ରନ୍ଧା ହେବା ନିମନ୍ତେ ପ୍ରଭୁ ଜଗନ୍ନାଥଙ୍କ ଅନୁମତି ଭିକ୍ଷା କରିଥିଲେ । ଏହି ପାକ କାର୍ଯ୍ୟ ସଂପନ୍ନ ନିମନ୍ତେ ଚିନ୍ତିତ ଗଜପତିଙ୍କୁ ସ୍ଵପ୍ନାଦେଶ ହୋଇଥିଲା କି, ନଳ ରାଜ୍ୟ ବିଦର୍ଭରେ
                        ମା” ହିଙ୍ଗୁଳା ପୂଜା ପାଉଛନ୍ତି । ମା"ଙ୍କୁ ସନ୍ତୁଷ୍ଟ କରି ଅଣାଯାଇ ପାରିଲେ ପାକ ପ୍ରସ୍ତୁତିର ସମସ୍ତ ନିୟୋଗୀ ଦାୟିତ୍ଵ ମା” ନିଜେ ବହନ କରିବେ । ସ୍ଵପ୍ନାଦେଶ ପରେ ଗଜପତି ନଳ ରାଜ୍ୟ ଯାଇ ହିଙ୍ଗୁଳା ଦେବୀଙ୍କୁ ଧ୍ୟାନ ଯୋଗ ପୂର୍ବକ ସନ୍ତୁଷ୍ଟ କରାଇ ଶ୍ରୀକ୍ଷେତ୍ର ପୁରୀକୁ ଅଣାଇଥିଲେ
                        । ସେହିଦିନ ଠାରୁ ଶ୍ରୀ ମନ୍ଦିରରେ ଦେବୀ ହିଙ୍ଗୁଳା ପାକଶାଳାର ଅଧିକାରିଣୀ ଭାବେ ପୂଜିତା । ଷାଟିଏ ପଉଟି ଭୋଗ ରନ୍ଧା ହେବା ନିମନ୍ତେ ରୋଷଶାଳାରେ ପ୍ରଥମ ଅଗ୍ନି ସଂଯୋଗ ପୂର୍ବରୁ ସର୍ବାଗ୍ରେ ହିଙ୍ଗୁଳା ଦେବୀଙ୍କୁ ହୋମ ଓ ବିଧି ବିଧାନ  ଆରାଧନା କରାଯାଇଥାଏ । ମହାପ୍ରସାଦ ରନ୍ଧନରେ ମା"
                        ହିଙ୍ଗୁଳାଙ୍କର ଭୂମିକା ଏଭଳି ଗୁରୁତ୍ଵପୂର୍ଣ୍ଣ ଥାଏ ଯେ ଆଜିର ବିଜ୍ଞାନ ମଣିଷ ଆଶ୍ଚର୍ଯ୍ୟର ସହ ଚିନ୍ତା କରୁଛି । ୧୦ ଗୋଟି କୁଡୁଆ ଥାକ ଥାକ ରହି କେବଳ ତଳ କୁଡୁଆରେ ଅଗ୍ନି ବାଜିଲେ ବି ସମସ୍ତ କୁଡୁଆର ପାକ ଏକ ସଙ୍ଗେ ସିଦ୍ଧ ହୋଇଥାଏ । ଏହା ଯେ, ଅଗ୍ନିରୂପା, ଜ୍ବଳାମୟୀ ଦେବୀ
                        ହିଙ୍ଗୁଳାଙ୍କ ଅଲୌକିକତାର ଜ୍ୱଳନ୍ତ ନିଦର୍ଶନ ନିଃସନ୍ଦେହରେ କୁହାଯାଇପାରେ । </p>
                    <p class="text-center">ମା'ଙ୍କର କୃପା ଅସୀମ । ମାନବ ଜାତିପ୍ରତି ବିଶେଷତଃ ସାଧକ ଓ ଜିଜ୍ଞାସୁ ମାନଙ୍କ ପ୍ରତି ତାଙ୍କର କରୁଣା ଓ ସ୍ନେହ ଅବଣ୍ଣନୀୟ । ମା' ତାଙ୍କର ସନ୍ତାନକୁ କୋଳରେ ଧରିବାକୁ ବ୍ୟାକୁଳ ହେଉଥାଏ । ସେହିପରି ବିଶ୍ବ ରଙ୍ଗମଞ୍ଚରେ ଦୁର୍ଗା-ଲକ୍ଷ୍ମୀ ଏବଂ ସରସ୍ଵତୀ ତ୍ରିବିଧ ରୂପରେ ସେ ମାଆ ଅଲୌକିକ
                        ଲୀଳା କରିଥାନ୍ତି । ଗୁରୁ ମତ୍ସ୍ୟେନ୍ଦ୍ରନାଥଙ୍କ ନିର୍ଦେଶରେ ଗୋରଖନାଥ ସେହି କରାଚୀ ନିକଟସ୍ଥ ହିଙ୍ଗୁଳାଙ୍କ ପୀଠ ବା ହିଞ୍ଜଳିକା ପୀଠରେ ..ମା' ହିଙ୍ଗଳାଙ୍କୁ ଦର୍ଶନ କରି ତାଙ୍କ ଆଶୀର୍ବାଦରୁ ସିଦ୍ଧିଲାଭ କରିଥିଲେ । ଆସାମର ଜ୍ୱାଳାମୁଖୀ ତୀର୍ଥରେ ମଧ୍ଯ ଦେବୀ ହିଙ୍ଗୁଳା, ହିଙ୍ଗୁଳେଇ
                        ଓ ହିଙ୍ଗୁଳାକ୍ଷୀ ରୂପେ ପୂଜା ପାଉଛନ୍ତି । ଅଗ୍ନିର ବଣ୍ଣ ହିଙ୍ଗୁଳ ପରି ଲାଲ ଥିବାରୁ ତାଳଚ଼େରର ଗୋପାଳପ୍ରସାଦ ଗ୍ରାମରେ ସେହି ଅଗ୍ନିରୂପା ଦେବୀଙ୍କ ନାମ ହିଙ୍ଗୁଳା । ଭାରତ ବାହାରେ କାବୁଲ‌ରେ ମଧ୍ଯ ହିଙ୍ଗୁଳାପୀଠ ରହିଛି । ଖୋର୍ଦ୍ଧାର କାଇପଦର ଶକ୍ତି ପୀଠରେ ହିନ୍ଦୁ ଓ ମୁସଲମାନ
                        ଉଭୟ ଶ୍ରଦ୍ଧା-ଭକ୍ତିରେ ଏ ଦେବୀକୁ ପୂଜା କରନ୍ତି । ସେହିପରି ସାରା ଓଡ଼ିଶାରେ ଓ ଓଡ଼ିଶା ବାହାରେ ପଶ୍ଚିମବଙ୍ଗ, ବିହାର ଆଦି ରାଜ୍ୟରେ ଶଙ୍ଖଚିଲା ଅଞ୍ଚଳର ଅଗ୍ନିସ୍ଵରୂପା ମା' ହିଙ୍ଗୁଳା ସର୍ବତ୍ର ପୂଜିତା ଓ ପରିଚ଼ିତା । ସେ ବୈଷ୍ଣବୀ ରୂପେ ଶଙ୍ଖଚିଲା ଶକ୍ତିପୀଠରେ ପୂଜା ପାଆନ୍ତି
                        । ସାତ୍ତ୍ୱିକ ଭାବରେ ତାଙ୍କୁ ପୂଜା ଆରାଧନା କରାଯାଏ । ତାଳଚ଼େରରେ ମା' ହିଙ୍ଗୁଳାଙ୍କ ସମ୍ମୁଖରେ ବଳିପଡ଼େ । ସେ ସେଠାରେ ଭୈରବୀ ରୂପେ ପୂଜିତା । ସେହି ମା'ର କୃପାରୂ ଏ ଅଞ୍ଚଳରେ ପ୍ରତି ଗ୍ରାମରେ ଏକତା, ସମତା ଓ ମମତା ପ୍ରତିଷ୍ଠା ହୋଇପାରିଛି ।
                    </p>




                </div>
            </div>


        </div>
    </section>
        </div>
    )
}