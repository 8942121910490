import React from "react";

export default function Donation(){
    return (
        <div>
        {/* <section class="abouthero innerhero">
   <div class="container">
       <div class="col-md-8 col-sm-8 col-xs-12">
           <div class="inner-caption animated fadeInLeft">
               
               <h1>ଦାନ (DONATION)</h1>
           </div>
       </div>
   </div>
</section> */}

<section class="breadcrumb-section">
   <div class="container">
       <div class="row">
           <div class="col-md-12">
           <div class="inner-caption animated fadeInLeft">
               
               <h1>ଦାନ (DONATION)</h1>
           </div>
               <nav class="breadcrumb breadcrumb-custom">
                   <a class="breadcrumb-item" href="/">Home</a>
                   <a class="breadcrumb-item" href="/about">About</a>
                   <span class="breadcrumb-item active">Donation</span>
               </nav>
           </div>
       </div>
   </div>
</section>
<section class="about">
   <div class="container">
       <div class="clear"></div>

       <div class="col-xs-12 animateOnScroll fadeInUp">
           <div class="aboutsubinner">
               <div class="col-md-5 col-md-offset-4">
                   <div class="col-md-6 col-sm-8 col-xs-12">
                       <h3>ଦାନ</h3>
                   </div>
               </div>
               <div class="clear"></div>
     <p class="text-center">ଶ୍ରଦ୍ଧାଳୁମାନଙ୍କୁ ହିଙ୍ଗୁଳା ପୀଠର ଉନ୍ନତି ଓ ପ୍ରଗତି ପାଇଁ ନିମ୍ନ ପ୍ରଦତ୍ତ ବ୍ୟାଙ୍କ ଏକାଉଣ୍ଟ୍ ରେ ଦାନ କରିବା ପାଇଁ ଅନୁରୋଧ।</p>
               <h4 class="text-center"><u>STATE BANK AC</u><br/>
   HINGULA COMMITTEE, Sankhachila</h4>
                <p class="text-center">AC No. 30482728210</p>
                <p class="text-center">IFSC CODE. SBIN001820</p>
                <h4 class="text-center"><u>CANARA BANK AC</u><br/>
   HINGULA COMMITTEE, Sankhachila</h4>
   <p class="text-center">AC No. 1251101023889</p>
                <p class="text-center">IFSC CODE. CNRB0001251</p>
               <p class="text-center"><strong>ବିଦ୍ର: ଦାନ କରିବା ପରେ ତାହାର ଫଟୋକପି ନିମ୍ନ whatsapp ନମ୍ବର ରେ ପଠାଇବା ପାଇଁ ଅନୁରୋଧ।</strong></p>
               <p class="text-center">Whatsapp.8480208429</p>
               <p class="text-center">Mob no. 8480208429</p>
               <p class="text-center"><u>Mail</u><br/>hingulasankhachila@gmail.com</p>
               
               <p class="text-center"><strong>କର୍ମମୟ ଜୀବନରେ ଭକ୍ତ ଯେତେବେଳେ ନିଜର ଜୀବିକା କିମ୍ବା ଯେ କୌଣସି ଶୁଭ କାର୍ଯ୍ୟ ଆରମ୍ଭ କରେ, ଠିକ୍ ସେତିକି ବେଳେ ସେ ମା ହିଙ୍ଗୁଳାଙ୍କୁ ସ୍ମରଣ କରିଥାଏ। ପ୍ରଥମେ ମା’ଙ୍କ ଡବାରେ ଇଷ୍ଟ ବୃତି ରଖି ନିଜର ମଙ୍ଗଳ ମା ହିଙ୍ଗୁଳାଙ୍କ ନିକଟରେ ସମର୍ପଣ କରି ମନସ୍କାମନା ପୂରଣ କରିଥାଏ। ଶ୍ରଦ୍ଧାଳୁ ମାନଙ୍କ ର ସେହି ଡବା ଅର୍ଥ ମା ହିଙ୍ଗୁଳାଙ୍କ ପାଣ୍ଠି ରୂପେ ମା’ଙ୍କ ରିତୀ ନିତୀ, ପୂଜା ପାର୍ବଣ, ଦୈନିକ ନୀତି ଖେଚୁଡ଼ି ଓ ବାର୍ଷିକ ମହାଯଜ୍ଞ ସମୟରେ ଖର୍ଚ୍ଚ ହୋଇଥାଏ। ସେଥିପାଇଁ ଘରେ ଘରେ ମା ହିଙ୍ଗୁଳାଙ୍କ ହୁଣ୍ଡି ବା ଦାନବାକ୍ସ ଡବା ଶୋଭା ପାଇଥାଏ।</strong>
               </p>
               <p class="text-center"><strong>ଶ୍ରଦ୍ଧାଳୁ ଭକ୍ତ ମାନଙ୍କର ଅକୁଣ୍ଠ ଦାନ ହିଁ ଉକ୍ତ ଅନୁଷ୍ଠାନର ଏକ ମାତ୍ର ଆୟପନ୍ଥା। କିଛି ଶ୍ରଦ୍ଧାଳୁମାନେ ମା' ଙ୍କ ଦୈନନ୍ଦିନ ନିତୀ ଓ ବାଲ୍ୟ ଭୋଗ, ଖେଚୁଡ଼ି ଭୋଗ ପାଇଁ ଜମି ଦାନ କରିବା ସଙ୍ଗେ ସଙ୍ଗେ ବିଭିନ୍ନ ବିଭାଗ ପାଇଁ ସ୍ଥାୟୀ ଜମା କରି ସେଥିରୁ ଉପଲବ୍ଧ ଅର୍ଥରେ ନିତୀ କାର୍ଯ୍ୟକ୍ରମ ସମ୍ପାଦିତ ହୋଇଥାଏ। ବାର୍ଷିକ ମହାଯଜ୍ଞ ସମୟରେ ଭକ୍ତମାନଙ୍କର ଅକୁଣ୍ଠ ଦାନ ମେଳା ପରିଚାଳନା ରେ ବଡ଼ ସହାୟକ ହୋଇଥାଏ। ଭକ୍ତ ଓ ଶ୍ରଦ୍ଧାଳୁ ଦାତା ମାନଙ୍କ ଅକୁଣ୍ଠ ଦାନ ରେ ନୀତି ଖେଚୁଡ଼ି ସ୍ଥାୟୀ ଜମା, ପଣା ହାଣ୍ଡି ସ୍ଥାୟୀ ଜମା, ନାମ ଯଜ୍ଞ ସ୍ଥାୟୀ ଜମା, ବାଲ୍ୟଲୀଳା ସ୍ଥାୟୀ ଜମା, ପାଠାଗାର ସ୍ଥାୟୀ ଜମା ଇତ୍ୟାଦି ହିଙ୍ଗୁଳା କମିଟି, ଶଙ୍ଖଚିଲା ଦ୍ଵାରା ପରିଚାଳନା କରାଯାଉଛି।</strong></p>
               

           </div>
       </div>


   </div>
</section>
   </div>
    )
}
