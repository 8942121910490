import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Pooja from "./components/Pooja";
import Festival from "./components/Festival";
import Gallery from "./components/Gallery";
import Location from "./components/Location";
import NavBar from "./components/NavBar";
import FooteR from "./components/Footer";
import History from "./components/History";
import Architecture from "./components/Architecture";
import Deities from "./components/Deities";
import Features from "./components/Features"
import Hingulaphoto from "./components/hingula-photo";
import Templephoto from "./components/temple-photos";
import Fphotos from "./components/festival-photos"
import Videos from "./components/videos";
import Donation from "./components/Donation";
import Management from "./components/Management";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route component={Home} path='/' exact />
        <Route component={About} path='/about' />
        <Route component={Pooja } path='/pooja' />
        <Route component={Festival} path='/festivals' />
        <Route component={Gallery} path='/gallery' />
        <Route component={Location} path='/location'/>
        <Route component={History} path='/history'/>
        <Route component={Architecture} path= '/architecture'/>
        <Route component={Deities} path='/deities'/>
        <Route component={Features} path='/special-features'/>
        <Route component={Hingulaphoto} path='/maahingula-photos'/>
        <Route component={Templephoto} path='/temple-photos'/>
        <Route component={Fphotos} path='/festival-photos'/>
        <Route component={Videos} path='/videos'/>
        <Route component={Donation} path='/donation'/>
        <Route component={Management} path='/management'/>
      </Switch>
      <FooteR/>
      
    </BrowserRouter>
    
  )

}

export default App;
