import React from "react";

export default function Footer(){
    return(
        <div>
             <footer>
        <div class="container" align="center">

            {/* <!-- <div class="social col-md-5">
                <ul>
                    <li><a href="https://www.facebook.com/#/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://twitter.com/#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/#/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.youtube.com/c/#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                </ul>
            </div> -->

            <!-- <div class="col-md-6">
                <div class="single"> -->
            <!--<h2></h2>-->
            <!-- <div class="input-group">
                        <input id="input_email" type="email" class="form-control" placeholder="Subscribe to newsletter by giving your email here">
                        <span class="input-group-btn">
                        <button class="btn btn-theme" id="subscribe_newsletter" >Subscribe</button>
                    </span>

                    </div>
                    <div><span class="err-msg" id="input_email_error"></span></div>
                </div>
            </div> --> */}

            <div class="col-md-12">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/donation">Donation</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/pooja">Pooja Details</a></li>
                    <li><a href="/festivals">Festivals</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    <li><a href="/location">Location</a></li>
                    
                    {/* <!--<li><a href="blog/">Blogs</a></li>--> */}
                </ul>
                <br/><img src={process.env.PUBLIC_URL + "/logo.png"} alt="Hingula temple logo" title="Hingula Logo" width="100" height="100"/><br/><br/>
                <p style={{textAlign:"center"}}>Maa Hingula Temple Sankhachila.</p>
                <p style={{textAlign:"center"}}>Made by ❤️ with <a href="https://somesh-ss.herokuapp.com/"><h4>Somesh Srichandan Sahoo</h4></a></p>
                <p><small>© 2021 Maa Hingula Sankhachila. All Rights Reserved.</small></p>
            </div>



        </div>
    </footer>
        </div>
    )
}
