import React, { useEffect, useState } from "react";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

export default function Fphotos() {
    const [festivalphotoData, setFestivalphoto] = useState(null);
    useEffect(() => {
        sanityClient.fetch(`*[_type == "festivalphoto"]{
            mainImage,
            title
        }`)
            .then((data) => setFestivalphoto(data))
            .catch(console.error);
    }, []);
    return (
        <div>
            <section class="galleryhero innerhero">
                <div class="container">
                    <div class="col-md-8 col-sm-6 col-xs-12">
                        <div class="inner-caption animated fadeInLeft">
                            <h1>Temple Gallery</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <nav class="breadcrumb breadcrumb-custom">
                                <a class="breadcrumb-item" href="/">Home</a>
                                <a class="breadcrumb-item" href="/gallery">Gallery</a>
                                <span class="breadcrumb-item active">Temple Photos</span>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section class="gallerydiv1">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 album">
                            <h2 class="text-center">
                                Temple Photos
                            </h2>
                        </div>
                        <div class='list-group gallery'>
                            <div class="tab-content">
                                <div id="menu2" class="tab-pane fade in active">
                                    <div class="tz-gallery">
                                    {festivalphotoData && festivalphotoData.map((festivalphoto) => (
                                        <div class="row">
                                            <div class="col-md-3 col-xs-6 gallery-box">
                                               
                                                    <a class="lightbox img-thumbnail" href={urlFor(festivalphoto.mainImage).url()}>
                                                        <img src={urlFor(festivalphoto.mainImage).url()} alt={festivalphoto.title} title={festivalphoto.title} class="img-responsive" />
                                                        <p>
                                                            {festivalphoto.title}
                                                        </p>
                                                    </a>
                                            </div>
                                        </div>
                                         ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}