import React from "react";

export default function Deities(){
    return(
        <div>
             {/* <section class="abouthero innerhero">
        <div class="container">
            <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="inner-caption animated fadeInLeft">
                    <h1>The Divinity of Maa Hingula Temple</h1>
                </div>
            </div>
        </div>
    </section> */}

    <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                <div class="inner-caption animated fadeInLeft">
                    <h1>The Divinity of Maa Hingula Temple</h1>
                </div>
                    <nav class="breadcrumb breadcrumb-custom">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <a class="breadcrumb-item" href="/about">About</a>
                        <span class="breadcrumb-item active">Deities</span>
                    </nav>
                </div>
            </div>
        </div>
    </section>

    {/* <section class="inner-tabs-section">
        <div class="container">
            <div class="col-md-12 col-sm-12 col-xs-12 inner-tabs">
                <ul class="list-inline text-center">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/history">History</a></li>
                    <li><a href="/architecture">Architecture</a></li>
                    <li><a href="/deities" class="active">Deities</a></li>
                    <li><a href="/special-features">Features</a></li>
                    <li><a href="/">Maa Hingula</a></li>
                </ul>
            </div>
        </div>
    </section> */}

    <section class="drowpdown-innertabs-mob-section about">
        <div class="container text-center">
            <div class="dropdown drowpdown-innertabs-mob">
                <button class="btn btn-primary dropdown-toggle btn-innertab" type="button" data-toggle="dropdown" aria-expanded="true">Deities
            	<span class="caret"></span></button>
                <ul class="dropdown-menu">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/history">History</a></li>
                    <li><a href="/architecture">Architecture</a></li>
                    <li><a href="/special-features">Features</a></li>
                    <li><a href="/">Maa Hingula</a></li>
                </ul>
            </div>
        </div>
    </section>

    <section class="about">
        <div class="container">
            <div class="clear"></div>

            <div class="col-xs-12 animateOnScroll fadeInUp">
                <div class="aboutsubinner">
                    <div class="col-md-5 col-md-offset-4">
                        <div class="col-md-5 col-sm-4 col-xs-12" align="center"><img src={process.env.PUBLIC_URL + "assets/images/icon-deities.png"} class="img-responsive" alt="Maa Hingula Temple Deities" title="Maa Hingula Temple Deities"/><br/></div>
                        <div class="col-md-6 col-sm-8 col-xs-12">
                            <h3>Deities</h3>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <p class="text-center"><strong>ସକାଳର ସିନ୍ଦୂରା ଫାଟିବା ପୂର୍ବରୁ ମା'ଙ୍କ ର ମଙ୍ଗଳା ଆଳତୀ ଶେଷ ହୋଇ ଥାଏ। ଠିକ୍ ପରେ ପରେ ସ୍ନାନ ପର୍ବ, ବଡ଼ସିଂହାର ବେଶ , ଭକ୍ତ ମାନଙ୍କର ମାନସିକ ପୂଜାର୍ଚ୍ଚନା ଆରମ୍ଭ ହୋଇଥାଏ।</strong></p>
                    <p class="text-center"><strong> ମଧ୍ୟାହ୍ନ ୧୨.୩୦ମି ସମୟରେ ମା'ଙ୍କ ର ଅନ୍ନଭୋଗ ଲାଗି ହେବା ପରେ ଆନନ୍ଦ ବଜାର ଅନୁଷ୍ଠିତ ହୁଏ। ପୁନଶ୍ଚ ସନ୍ଧ୍ୟା ଆଳତି ଓ ନାମ ସଙ୍କୀର୍ତ୍ତନ ପରିବେଶ କୁ ଏକ ଅପୂର୍ବ ଦେବ ବୈଭବରେ ପରିପୂର୍ଣ କରିଥାଏ।</strong>
                    </p>
                    <p class="text-center"><strong>ପ୍ରାୟ ରାତ୍ର ୮.୩୦ମି. ସମୟରେ ମା'ଙ୍କ ର ଖେଚୁଡ଼ି ନୀତି ଲାଗି କରାଯାଇ ବିଶ୍ରାମ ପହଡ ହୁଏ।ପ୍ରତିମାସ ପୂର୍ଣ୍ଣମୀ, ଅମାବାସ୍ୟା, ସଂକ୍ରାନ୍ତି ତିଥିରେ ଶହ ଶହ ଭକ୍ତଙ୍କ ସମାଗମ ଉକ୍ତ ପୀଠସ୍ଥଳୀକୁ କୋଳାହଳ ମୁଖର କରିଥାଏ।</strong></p>


                </div>
            </div>


        </div>
    </section>
        </div>
    )
}